import { useTranslation } from "react-i18next";
import {useNavigate, useOutletContext} from "react-router-dom";
import { useEffect, useState } from "react";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { useLoading } from "../../Context/LoadingProvider";
import {patrollerService} from "../../Api/Services/PatrollerService";
import IdVerificationStep from "./StartCheckSteps/IdVerificationStep";
import VehicleVerificationStep from "./StartCheckSteps/VehicleVerificationStep";
import GearCheckStep from "./StartCheckSteps/GearCheckStep";
import QuestionerStep from "./StartCheckSteps/QuestionerStep";

interface CheckHistoryProps {
}

const StartCheck: React.FunctionComponent<CheckHistoryProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const navigate = useNavigate();
  const {
    setSubTitle,
    rider,
      setShowProfileInfo,
      setShowTabsNavbar
  } = useOutletContext<RiderProfileLayoutContextInterface>();

  const [step, setStep] = useState<number>(-1);
  const [needsVehicleCheck, setNeedsVehicleCheck] = useState<number>(0);
  const [continueAfterFakeId, setContinueAfterFakeId] = useState<boolean>(true);


  useEffect(() => {
      startLoading('rider-select');
      patrollerService.isRiderReadyForCheck(rider.riderId).then(() => {
        patrollerService.getCheckForRider(rider.riderId).then((check: any) => {
          let checkNumber = 0;

            if((check.gear_information !== null && check.gear_information !== undefined)){
                checkNumber = 3;
            }

            if((check.is_license_plate_valid !== null && check.is_license_plate_valid !== undefined) || (check.licence_plate !== null && check.licence_plate !== undefined)){
                checkNumber = 2;
            }

          if(check.is_id_valid !== null && check.is_id_valid !== undefined){
              checkNumber = 1;
          }

            setNeedsVehicleCheck(parseInt(check.needs_vehicle_check));
            setContinueAfterFakeId(check.continue_after_fake_id === 1 || check.continue_after_fake_id === '1');

          patrollerService.setCheckForRider(rider.riderId, check.id);

            setStep(checkNumber);
        }).finally(() => {
            finnishLoading('rider-select');
          setShowTabsNavbar(false);
          setShowProfileInfo(true);
          setSubTitle(t('T-Patroller Actions History'));
          finnishLoading('rider-select');
        });
      }).catch(() => {
          finnishLoading('rider-select');
        navigate(`/rider/${rider.riderId}/profile`);
      }).finally(() => {

      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubTitle();
  }, [t, setSubTitle]);

  return (
          <>
            <div className="flex flex-col justify-center items-center">
              {
                step === 0 &&
                <><IdVerificationStep setStep={(step: number) => setStep(step)} needsVehicleCheck={needsVehicleCheck} continueAfterFakeId={continueAfterFakeId}/></>
              }
              {
                step === 1 &&
                  <><VehicleVerificationStep setStep={(step: number) => setStep(step)} needsVehicleCheck={needsVehicleCheck} /></>
              }
            {
                step === 2 &&
                <><GearCheckStep setStep={(step: number) => setStep(step)} /></>
            }
                {
                    step === 3 &&
                    <><QuestionerStep /></>
                }
            </div>
          </>
  );
};

export default StartCheck;
