import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileImage from "../../Components/ProfileImage";
import ProfileDetails from "../../Components/ProfileDetails";
import { RiderModel } from "../../Api/Models/rider.model";
import { useLoading } from "../../Context/LoadingProvider";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { riderService } from "../../Api/Services/RiderService";
import { useTranslation } from "react-i18next";

interface RiderProfileLayoutProps {}

export interface RiderProfileLayoutContextInterface {
  setPageTitle: CallableFunction;
  rider: RiderModel;
  setRider: CallableFunction;
  showTabsNavbar: boolean;
  setShowTabsNavbar: CallableFunction;
  setHasGear: CallableFunction;
  setGearExchangeRequired: CallableFunction;
  setSubTitle: CallableFunction;
  subTitle: string;
  setGearMissing: CallableFunction;
  fetchRider: CallableFunction;
  setShowProfileInfo: CallableFunction;
}

const RiderProfileLayout: React.FunctionComponent<
  RiderProfileLayoutProps
> = () => {
  const { t } = useTranslation();

  const [title, setTitle] = useState<string>();
  const [subTitle, setSubTitle] = useState<string>(t("Gear Owned by the Rider") ?? "");
  const [showTabsNavbar, setShowTabsNavbar] = useState<boolean>(true);
  const [showProfileInfo, setShowProfileInfo] = useState<boolean>(true);
  const [hasGear, setHasGear] = useState<boolean>(true);
  const [gearMissing, setGearMissing] = useState<number | null>(null);
  const [gearExchangeRequired, setGearExchangeRequired] =
    useState<boolean>(false);
  const { startLoading, finnishLoading } = useLoading();

  let { riderId } = useParams();
  const { state } = useLocation();
  const [rider, setRider] = useState<RiderModel>(state?.riderModel);
  const { showInfo } = useInfoModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!rider) {
      if (!riderId) {
        showInfo(t("Invalid QR code"));
        navigate("/menu");
        return;
      }

      fetchRider();
    }
    //eslint-disable-next-line
  }, []);


  const fetchRider = () => {
    if (!riderId) {
      showInfo(t("Invalid QR code"));
      navigate("/menu");
      return;
    }
    startLoading("get-rider");

    riderService
      .get(riderId)
      .then((rider) => {
        setRider(rider);
      })
      .catch((e) => {
        showInfo(t("Invalid QR code"));
        navigate("/menu");
      })
      .finally(() => {
        finnishLoading("get-rider");
      });
  };

  const setPageTitle = useCallback(
    (titleToBeSet: string) => setTitle(titleToBeSet),
    []
  );

  return (
    <div className="view-container background-2">
      <div className="view-content">
        {title ? <div className="heading text-center">{title}</div> : null}
        {rider && (
          <>
            {showProfileInfo && (<div className="mb-4 flex flex-col items-center">
              <ProfileImage
                imageUrl={rider.image}
                changeImage={true}
                showRiderId={false}
                riderId={rider.riderId}
              />
              <br />
              <ProfileDetails
                rider={rider}
                hasGear={hasGear}
                gearMissing={gearMissing}
                gearExchange={gearExchangeRequired}
              />
            </div>)}
            <Outlet
              context={{
                setPageTitle,
                rider,
                setRider,
                showTabsNavbar,
                setShowTabsNavbar,
                setHasGear,
                setGearExchangeRequired,
                subTitle,
                setSubTitle,
                setGearMissing,
                fetchRider,
                setShowProfileInfo,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RiderProfileLayout;
