import { useTranslation } from "react-i18next";
import {useNavigate, useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { RiderProfileLayoutContextInterface } from "../RiderProfileLayout";
import ImageInput from "../../../Components/ImageInput";
import {patrollerService} from "../../../Api/Services/PatrollerService";
import {useLoading} from "../../../Context/LoadingProvider";

const VehicleVerificationStep: React.FunctionComponent<any> = ({setStep,needsVehicleCheck}) => {
    const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    setSubTitle,
      rider,
  } = useOutletContext<RiderProfileLayoutContextInterface>();
    const { startLoading, finnishLoading } = useLoading();
  const [isRealLicense, setIsRealLicense] = useState<boolean | undefined>(undefined);
  const [licenseImage, setLicenseImage] = useState<File[]>([]);

  const [licensePlate, setLicensePlate] = useState<string|null>(null);
  const [correctLicensePlateImage, setCorrectLicensePlateImage] = useState<File[]>([]);

  const [showRealLicensePlateFields, setShowRealLicensePlateFields] = useState<boolean>(false);

  const [showErrorMessages, setShowErrorMessages] = useState<boolean>(false);
    const [displayFakeIdMessage, setDisplayFakeIdMessage] = useState<boolean>(false);

  useEffect(() => {
    setSubTitle();
  }, [t, setSubTitle]);

  setSubTitle(t('Action'));

    useEffect(() => {
        if(needsVehicleCheck < 0){
            setStep(2);
        }

        if (!rider.licensePlate){
            setShowRealLicensePlateFields(true);
        }
        //eslint-disable-next-line
    }, []);

    const handleNext = () => {
        if ((isRealLicense === undefined && rider.licensePlate) && needsVehicleCheck === 1) {
            setShowErrorMessages(true);
            setTimeout(() => {
                setShowErrorMessages(false);
            }, 5000)
                return;
        }

        if (!isRealLicense && !showRealLicensePlateFields && licenseImage.length > 0) {
            setDisplayFakeIdMessage(true);
            setTimeout(() => {
                setShowRealLicensePlateFields(true);
            }, 1000);

            return;
        }

        if (isRealLicense === false && showRealLicensePlateFields && !licensePlate) {
            return;
        }

        startLoading('vehicle-verification');
        patrollerService.saveVehicleStepCheckForRider(rider.riderId, isRealLicense ?? true, licensePlate,licenseImage[0] ?? undefined, correctLicensePlateImage[0] ?? undefined)?.then(() => {
            setLicensePlate(null);
            setCorrectLicensePlateImage([]);
            setShowRealLicensePlateFields(false);
            setStep(2);
        }).finally(() => {
            finnishLoading('vehicle-verification');
        });
    }

  return (
      <>

          {showRealLicensePlateFields ? <>
              <div className="text-white text-center mb-4">
                  {t("What’s the Rider vehicle plate number?")}
              </div>
              {(showErrorMessages || needsVehicleCheck === 1) && <div className="text-red">{t("Please fill the correct license plate!")}</div>}
              <div className="flex flex-col justify-start items-start w-80">
                  <div className="mb-6 w-full">
                      <input className="input-field text-blue see-through-background mt-4 w-full" type="text" placeholder={t('License Plate') || ''} value={licensePlate ?? ''} onChange={(e) => setLicensePlate(e.target.value)}/>
                  </div>
              </div>


              <ImageInput
                  images={correctLicensePlateImage}
                  setImages={setCorrectLicensePlateImage}
                  title={t("Confirmation Picture")}
                  multiple={false}
                  required={isRealLicense === false}
              />
              {isRealLicense === false && (
                  <div className="text-white text-center mt-4">
                      {t("Please upload a picture of the fake license plate!")}
                  </div>
              )}
          </> : <>
              <div className="text-white text-center mb-4">
                  {t("Confirm the rider’s vehicle plate number?")}
              </div>
              {displayFakeIdMessage && <div className="text-red text-center mb-4 card">
                  {t("Different plate number reported")}
              </div>}
              <div className="license-plate">
                  {rider.licensePlate ?? "No license plate"}
              </div>
              {(showErrorMessages || needsVehicleCheck === 1) && <div className="text-red">{t("Please choose an option!")}</div>}
              <div className="flex flex-col justify-start items-start w-80">
                  <label className="checkbox mb-4">
                      <input
                          type="checkbox"
                          onChange={(e) => setIsRealLicense(e.target.checked)}
                          required
                          checked={isRealLicense === true}
                      />
                      <div className="checkbox__success-icon no-border see-through-background"></div>
                      <div className="checkbox__text text-white">{t("Yes")}</div>
                  </label>

                  <label className="checkbox mb-4">
                      <input
                          type="checkbox"
                          onChange={(e) => setIsRealLicense(!e.target.checked)}
                          required
                          checked={isRealLicense === false}
                      />
                      <div className="checkbox__success-icon no-border see-through-background"></div>
                      <div className="checkbox__text text-white">{t("No")}</div>
                  </label>
              </div>


              <ImageInput
                  images={licenseImage}
                  setImages={setLicenseImage}
                  title={t("Confirmation Picture")}
                  multiple={false}
                  required={isRealLicense === false}
              />
          </>}



        <div className="flex flex-col items-center mt-6">
          <div>
            <button
                type="submit"
                className="button button--green"
                onClick={() => handleNext()}
            >
              {t("Next")}
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center mt-4">
          <div>
            <button type="submit" className="button" onClick={() => {
                if (showRealLicensePlateFields) {
                    setShowRealLicensePlateFields(false);
                    return;
                }

                navigate('/');
            }}>
              {t("< Back")}
            </button>
          </div>
        </div>
      </>
  );
};

export default VehicleVerificationStep;
