import { t } from "i18next";
import React, {useEffect, useState} from "react";
import { RiderModel, RiderProductModel } from "../../Api/Models/rider.model";
import { ReturnStatus } from "../../Api/Models/return.model";
import {useOutletContext} from "react-router-dom";
import {RiderProfileLayoutContextInterface} from "../../Pages/Rider/RiderProfileLayout";
import grayClock from "../../Assets/gfx/icons/grayClock.png";
import exchangeIcon from "../../Assets/gfx/icons/exchangeIcon.png";
import warningIcon from "../../Assets/gfx/icons/warning.png";
import {patrollerService} from "../../Api/Services/PatrollerService";
import {useUser} from "../../Context/UserProvider";

interface GearsProps {
  gears: RiderProductModel[];
  rider: RiderModel;
}

const Gears: React.FunctionComponent<GearsProps> = ({
  gears,
}) => {
  const {
    setShowProfileInfo,
      setSubTitle,
      setShowTabsNavbar,
      rider,
  } = useOutletContext<RiderProfileLayoutContextInterface>();
  const [displayAlreadyChecked, setDisplayAlreadyChecked] = useState(false);
  const [lastCheckDoneBy, setLastCheckDoneBy] = useState("");
  const {user} = useUser();

  setShowTabsNavbar(true);
  setShowProfileInfo(true);
  setSubTitle(t('Gear Owned by the Rider'));

  const getGearColor = (gear: RiderProductModel) => {
    if (gear.discontinued) {
      return "black";
    }

    if (gear.returnDate && gear.returnStatus !== ReturnStatus.MISSING) {
      return "grey";
    }

    if (!gear.exchangeDays || gear.returnStatus === ReturnStatus.MISSING) {
      return "red";
    }

    if (gear.exchangeDays <= 7) {
      return "orange";
    }

    return "green";
  };

  useEffect(() => {
    patrollerService.isRiderReadyForCheck(rider.riderId).then(() => {
    }).catch((result) => {
      setLastCheckDoneBy(result.patrollerId ?? "");
      setDisplayAlreadyChecked(true);
    });
  }, [rider]);

  return (
    <div className="gap-4px nav-card mb-4 gears">
      {(displayAlreadyChecked && user) && (<><div className="card card-opacity flex-col items-center justify-center flex mb-1">
            <img src={warningIcon} alt=""/>
            <div className="font-bold text-center">
              {user.id === lastCheckDoneBy ? t("You already checked this rider in the past 3 days. Please look for a new Rider!" ) : t("This rider was already checked within one day by another T-patroller. Please look for a new Rider!")}
            </div></div></>
      )}
      {gears.length > 0 && (
        <div className="gears__kit-details">
          {gears.map((gear, index) => (
            <React.Fragment key={index}>
              <div className="gears__kit-details__item ">
                <div className="items-start">
                  <img src={gear.productImage} alt="package name" />
                  <p className="font-bold" style={{fontSize: '12px'}}>
                    {gear.productName}
                    <span className="text-blue">{gear.variationName ? ` - ${gear.variationName}` : ""}</span>
                    <br />
                    {gear.returnDate ? (
                      <span className="font-8">
                        <img src={grayClock} className="inline" alt=""
                             style={{ width: "13px", marginRight: '2px' }}/>&nbsp;
                        <span className="text-orange">{gear.returnDate}</span>
                      </span>
                    ) : (
                      <span className="font-8">
                        <img src={grayClock} className="inline" alt=""
                             style={{ width: "13px", marginRight: '2px' }}/>&nbsp;
                        <span className="text-orange">{gear.assignedAt}</span>
                      </span>
                    )}
                    <br />
                    {gear.discontinued ? (
                      <span className="font-8">{t("Discountinued")}</span>
                    ) : (gear?.exchangeDays ?? 0) > 0 ? (
                      <span className="font-8">
                        <img
                          src={exchangeIcon}
                          alt="Exchange"
                          className="inline"
                          style={{ width: "11px" }}
                        />
                        <span className={"text-" + getGearColor(gear)}>
                          {gear.exchangeDays}
                        </span>
                      </span>
                    ) : null}
                  </p>
                </div>
                <div className={"status-bubble " + getGearColor(gear)}></div>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default Gears;
