import { useTranslation } from "react-i18next";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import {useNavigate} from "react-router-dom";

interface ProfileImageProps {
  imageUrl: string;
  riderId: string;
  showRiderId?: boolean;
  changeImage?: boolean;
}

const ProfileImage: React.FunctionComponent<ProfileImageProps> = ({
  imageUrl,
  riderId,
  showRiderId = true,
  changeImage = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      <div className="rider-avatar mb-2">
        {changeImage ? (
            <Gallery
                uiElements={
                  (changeImage && imageUrl.includes("rider-default"))
                      ? [
                        {
                          name: "change-button",
                          ariaLabel: "Change image",
                          order: 9,
                          isButton: true,
                          html: {
                            isCustomSVG: true,
                            inner:
                                '<svg fill="none" stroke="white" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" /></svg>',
                            outlineID: "pswp__icn-cstm-btn",
                          },
                          appendTo: "bar",
                          onClick: (e, el, pswpInstance) => {
                            navigate(`/rider/${riderId}/change-image`);
                            pswpInstance.close();
                          },
                        },
                      ]
                      : []
                }
            >
            <Item original={imageUrl}>
              {({ ref, open }) => (
                <>
                  <img
                    onClick={open}
                    src={imageUrl}
                    alt="Profile"
                    style={{
                      marginRight: "10px",
                      width: "64px",
                      height: "64px",
                      objectFit: "contain",
                    }}
                  />
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  ></svg>
                </>
              )}
            </Item>
          </Gallery>
        ) : (
          <img src={imageUrl} alt="rider avatar" />
        )}
      </div>
      {showRiderId && (
        <p className="text-white text-small">
          {t("Rider ID")}: {riderId}
        </p>
      )}
    </div>
  );
};

export default ProfileImage;
