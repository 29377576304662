import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useUser } from "./Context/UserProvider";
import Layout from "./Layout";
import RiderProfileLayout from "./Pages/Rider/RiderProfileLayout";
import ForgotPassowrd from "./Pages/ForgotPassword";
import FunctionsMenu from "./Pages/FunctionMenu/FunctionsMenu";
import Login from "./Pages/Login";
import NotFoundPage from "./Pages/NotFoundPage";
import RiderSearch from "./Pages/Rider/RiderSearch";
import RiderProfile from "./Pages/Rider/RiderProfile";
import ExchangeItem from "./Components/Profile/ExchangeItem";
import VehicleSelect from "./Pages/Rider/VehicleSelect";
import FloatingBackButton from "./Components/FloatingBackButton";
import Dashboard from "./Pages/Dashboard";
import ResetPassword from "./Pages/ResetPassword";
import StartCheck from "./Pages/Rider/StartCheck";
import DailyGoalRequests from "./Pages/DailyGoalRequests";
import CreateDailyGoalRequest from "./Pages/CreateDailyGoalRequest";
import ChangeImage from "./Pages/Rider/ChangeImage";

export interface IAppProps {}

const PrivateRoutes: React.FunctionComponent<IAppProps> = () => {
  const { user } = useUser();
  const location = useLocation();

  return user ? (
    <>
      {location.pathname !== "/menu" ? <FloatingBackButton /> : null}
      <Outlet />
    </>
  ) : (
    <Navigate to="/" replace state={{ redirectPath: location.pathname }} />
  );
};

const GuestRoutes: React.FunctionComponent<IAppProps> = () => {
  const { user } = useUser();
  const { state } = useLocation();

  return !user ? (
    <Outlet />
  ) : (
    <Navigate to={state?.redirectPath ?? "/menu"} replace />
  );
};

const App: React.FunctionComponent<IAppProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<GuestRoutes />}>
            <Route index element={<Login />}></Route>
            <Route path="forgot-password" element={<ForgotPassowrd />}></Route>
          </Route>

          <Route path="/" element={<PrivateRoutes />}>
            <Route index path="menu" element={<FunctionsMenu />}></Route>
            <Route index path="reset-password" element={<ResetPassword />}></Route>

            <Route path="rider">
              <Route path="search" element={<RiderSearch />} />
            </Route>

            <Route path="dashboard" element={<Dashboard />} />
            <Route path="daily-goal-requests" element={<DailyGoalRequests />} />
            <Route path="create-daily-goal-request" element={<CreateDailyGoalRequest />} />

            <Route path="rider/:riderId" element={<RiderProfileLayout />}>
              <Route path="change-image" element={<ChangeImage />} />
              {/*<Route path="size-select" element={<SizeSelect />} />*/}
              <Route path="vehicle-select" element={<VehicleSelect />} />
              <Route path="profile" element={<RiderProfile />} />
              <Route path="start-check" element={<StartCheck />} />
              <Route
                path="exchange-item/:warrantyId/:riderProductId"
                element={<ExchangeItem />}
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
