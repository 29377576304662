import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { useLoading } from "../../Context/LoadingProvider";
import {patrollerService} from "../../Api/Services/PatrollerService";
import greenCheckIcon from "../../Assets/gfx/icons/greenCheck.png";

interface CheckHistoryProps {
}

const CheckHistory: React.FunctionComponent<CheckHistoryProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const {
    setSubTitle,
    rider,
      setShowProfileInfo
  } = useOutletContext<RiderProfileLayoutContextInterface>();

  const [history, setHistory] = useState<any[]>([]);

  setShowProfileInfo(false);
  const fetchHistory = () => {
    startLoading("get-history");
    patrollerService
      .listHistory(rider.riderId)
      .then((historyItems) => {
        setHistory(historyItems);
      })
      .finally(() => {
        finnishLoading("get-history");
      });
  };

  useEffect(() => {
    fetchHistory();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSubTitle();
  }, [t, setSubTitle]);

  setSubTitle(t('T-Patroller Actions History'));

  const laravelDateTimeToDMY = (laravelDateTime: string) => {
    var parts = laravelDateTime.split(' ');
    var datePart = parts[0];

    var dateParts = datePart.split('-');
    var year = dateParts[0];
    var month = dateParts[1];
    var day = dateParts[2];

    return day + '-' + month + '-' + year;
  }

  return (
          <>
            <div className="flex flex-col justify-center items-center card">
              {history.map((historyItem, index) => {
                  return <>
                      <div key={index} className="flex justify-between mb-4" style={{width: '100%'}}>
                        <div style={{width: "15%"}}><img src={greenCheckIcon} alt="" style={{width: "22px"}}/></div>
                        <div style={{width: "85%"}}>
                          <p className="font-bold mb-1" style={{fontSize: "14px"}}>{historyItem.message}</p>
                          <p className="text-small"><span className="text-orange">{laravelDateTimeToDMY(historyItem.created_at)}</span><span className="text-blue ml-1">{t('By')} {historyItem.patroller_name}</span></p>
                        </div>
                      </div>
                  </>;
              })}
            </div>
          </>
  );
};

export default CheckHistory;
