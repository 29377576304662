import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingProvider";
import { useUser } from "../Context/UserProvider";
import { useTranslation } from "react-i18next";
import { patrollerService, DashboardInformation } from "../Api/Services/PatrollerService";
import profilePlaceholder from "../Assets/gfx/profile.png";
import awardIcon from "../Assets/gfx/icons/award.png";

const Dashboard: React.FunctionComponent<object> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { t } = useTranslation();
  const [dasboardInformation, setDashboardInformation] = useState<DashboardInformation | null>(null);
  const { user } = useUser();

    useEffect(() => {
        startLoading("dashboard");
        patrollerService.getDashboardInformation().then((res) => {
            setDashboardInformation(res);
            console.log(res);
            finnishLoading("dashboard");
        });
        //eslint-disable-next-line
    }, []);



  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="">
          <div className="card-list">

              <div className="mb-4 flex flex-col items-center">
                  <div className="flex flex-col items-center">
                      <div className="rider-avatar mb-2">
                          <img src={profilePlaceholder} alt=""/>
                        </div>
                    </div>
                    <div className="heading heading--small text-center">
                        {user?.name}
                    </div>
                  <div className="fit-content card card-opacity card--smaller-padding flex items-center justify-center">
                      <img src={awardIcon} alt="" style={{height: "28px"}}/>
                      <p className="font-bold">{t('Quality Score')}:</p>
                      <p className="text-orange font-bold ml-6">{dasboardInformation?.qualityScore}</p>
                  </div>
                  <br/>
                  <div className="card card-opacity">
                      <p className="font-bold text-center">{t('Daily Plan')}</p>
                      <br/>
                      <div className="flex items-center justify-between">
                         <p className="font-bold">{t("Check "+ (dasboardInformation?.currentDailyGoal ?? 30) +" Riders")}</p>
                          <svg
                              width="120" height="120" viewBox="0 0 120 120"
                              className={"circular-progress progress-" + Math.round((dasboardInformation?.checksToday ?? 0) / (dasboardInformation?.currentDailyGoal ?? 30) * 100)}
                          >
                              <circle className="bg"></circle>
                              <circle className="fg"></circle>
                              <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#F8099D">{Math.round((dasboardInformation?.checksToday ?? 0) / (dasboardInformation?.currentDailyGoal ?? 30) * 100)}% {dasboardInformation?.checksToday}/{(dasboardInformation?.currentDailyGoal ?? 30)}</text>
                          </svg>
                      </div>
                  </div>
                  <div className="heading heading--small text-center">
                      {t('Monthly KPI')}
                  </div>
                  <div className="card card-opacity">
                      <table style={{width: "100%"}}>
                          <tbody>
                              <tr>
                                  <td className="font-bold text-blue">{t("Number of audits:")}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.total ?? 0}</td>
                              </tr>
                              <tr><td><br/></td></tr>
                              <tr>
                                  <td className="font-bold text-blue">{t("Audit score:")}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.auditscore ?? 0}</td>
                              </tr>
                              <tr><td><br/></td></tr>
                              <tr>
                                  <td className="font-bold text-blue">{t('Accuracy')}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.accuracy ?? 0}</td>
                              </tr>
                              <tr><td><br/></td></tr>
                              <tr>
                                  <td className="font-bold text-blue">{t('Survey')}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.survey ?? 0}</td>
                              </tr>
                              <tr><td><br/></td></tr>
                              <tr>
                                  <td className="font-bold text-blue">{t('Assessment')}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.assessment ?? 0}</td>
                              </tr>
                              <tr><td><br/></td></tr>
                              <tr>
                                  <td className="font-bold text-blue">{t('Attendance')}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.attendance ?? 0}</td>
                              </tr>
                              <tr><td><br/></td></tr>
                              <tr>
                                  <td className="font-bold text-blue">{t('Date')}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.scoreDate ?? ""}</td>
                              </tr>
                              <tr><td><br/></td></tr>
                              <tr>
                                  <td className="font-bold text-blue">{t("Final score:")}</td>
                                  <td className="font-bold text-orange">{dasboardInformation?.kpiStatistics?.score ?? 0} pt</td>
                              </tr>
                              <tr><td><br/></td></tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              <div className="flex flex-col items-center mt-6">
                  <button
                      onClick={() => {
                          navigate('/daily-goal-requests')
                      }}
                      className="button"
                  >
                      {t("Daily Goal Requests")}
                  </button>
              </div>
            <div className="flex flex-col items-center mt-6">
              <button
                  onClick={() => {
                    navigate('/')
                  }}
                  className="button"
              >
                <i className="icon-arrow-left button__icon button__icon--prefix"></i>
                {t("Back")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
