import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "../../Context/LoadingProvider";
import { useUser } from "../../Context/UserProvider";
import OrderListIcon from "../../Assets/gfx/icons/nav/order-list.svg";
import DashboardIcon from "../../Assets/gfx/icons/dashboardIcon.png";
import { useTranslation } from "react-i18next";

interface FunctionsMenuProps {}

const FunctionsMenu: React.FunctionComponent<FunctionsMenuProps> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { logout } = useUser();
  //const [showQrReader, setShowQrReader] = useState<boolean>(false);
  const { t } = useTranslation();

  function askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (
        result
      ) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== "granted") {
        console.log("We weren't granted permission.");
      }
    });
  }

  useEffect(() => {
    askPermission();
    //eslint-disable-next-line
  }, []);

  const handleLogout = () => {
    startLoading("logout");
    logout()
      .then(() => {
        navigate("/");
      })
      .finally(() => {
        finnishLoading("logout");
      });
  };

  /*const closeQrReaderModal = () => {
    setShowQrReader(false);
  };*/

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="limited-width-content">
          <div className="card-list">
            <Link
              to={"/rider/search"}
              className={
                "nav-card"
              }
            >
              <img
                src={OrderListIcon}
                className="nav-card__icon"
                alt="Rider search"
              />
              <div className="nav-card__title">{t("Rider Search")}</div>
            </Link>
            <Link
                to={"/dashboard"}
                className={
                  "nav-card"
                }
            >
              <img
                  src={DashboardIcon}
                  className="nav-card__icon"
                  alt="Rider search"
              />
              <div className="nav-card__title">{t("Dashboard")}</div>
            </Link>
            <div style={{height: "25px"}}></div>
            <div className="text-center mt-12">
              <button
                  type="submit"
                  className="button button--orange button--small"
                  onClick={() => navigate('/reset-password')}
              >
                {t("Reset Password")}
              </button>
            </div>
            <div className="text-center mt-12">
              <button
                type="submit"
                className="button button--orange button--small"
                onClick={handleLogout}
              >
                {t("Logout")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunctionsMenu;
