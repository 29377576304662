import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingProvider";
import { useTranslation } from "react-i18next";
import {patrollerService, DailyGoalRequests as DailyGoalRequestInterface} from "../Api/Services/PatrollerService";

const DailyGoalRequests: React.FunctionComponent<object> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { t } = useTranslation();
  const [dailyGoalRequests, setDailyGoalRequests] = useState<DailyGoalRequestInterface[] | null>(null);
  const [inThisMonth, setInThisMonth] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<number>(-1);

    useEffect(() => {
        startLoading("daily-goal-requests");
        patrollerService.getDailyGoalRequests().then((res) => {
            setDailyGoalRequests(res);
            console.log(res);
            finnishLoading("daily-goal-requests");

            const date = new Date();
            const month = date.getMonth();
            setInThisMonth(res.filter((request) => {
                return new Date(request.date ?? '').getMonth() === month;
            }).length);
        });
        //eslint-disable-next-line
    }, []);

    const filterRequests = (requests: DailyGoalRequestInterface[]) => {
        return dailyGoalRequests?.filter((request) => {
            if (selectedFilter === 1){
                return request.approved_at === null;
            }
            if (selectedFilter === 2){
                return request.approved_at != null && request.approved;
            }
            if (selectedFilter === 3){
                return request.approved_at != null && !request.approved;
            }

            return true;
        })
    };



  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="">
          <div className="card-list">
              <h2 className="heading heading--small text-center">
                    {t("Goal requests list")}
              </h2>
              <div className="flex justify-center"><p className="card card-opacity w-fit-content">{inThisMonth} {t("in this month")}</p></div>
              <div className="flex justify-start">
                  <div className="select-minimal-wrapper mr-2">
                      <select
                          dir="rtl"
                          onChange={(e) => setSelectedFilter(parseInt(e.target.value))}
                      >
                          <option value={-1}>{t("All")}</option>
                          <option value={1}>{t("Pending")}</option>
                          <option value={2}>{t("Approved")}</option>
                          <option value={3}>{t("Declined")}</option>
                      </select>
                  </div>
              </div>
              <div style={{height: "300px", overflow: "scroll"}}>
                  {filterRequests(dailyGoalRequests ?? [])?.map((request, index) => {
                      return (
                          <div key={index} className="card card-opacity mb-4">
                              <div className="flex justify-between items-center">
                                  <div className="flex flex-col font-12">
                                      <span className="font-bold font-14">30 {">>"} {request.goal}</span>
                                      <p><span className="font-bold">{t('Date')}: </span> {request.date}</p>
                                      <p><span className="font-bold">{t('Note')}: </span> {request.modification_request}</p>
                                      <p><span className="font-bold">{t('Supervisor note')}: </span> {request.modification_response}</p>
                                  </div>
                                  <div className="flex flex-col items-center justify-center font-10">
                                      {request.approved_at === null && <>
                                          <div
                                              className={
                                                  "status-bubble blue"
                                              }
                                          ></div>
                                          <span>{t('Pending')}</span>
                                      </>}
                                      {request.approved_at != null && request.approved && <>
                                          <div
                                              className={
                                                  "status-bubble good"
                                              }
                                          ></div>
                                          <span>{t('Approved')}</span>
                                      </>}
                                      {request.approved_at != null && !request.approved && <>
                                          <div
                                              className={
                                                  "status-bubble bad"
                                              }
                                          ></div>
                                          <span>{t('Declined')}</span>
                                      </>}
                                  </div>
                              </div>
                          </div>
                      )
                  })}
              </div>

              <div className="flex flex-col items-center mt-6">
                  <button
                      onClick={() => {
                          navigate('/create-daily-goal-request')
                      }}
                      className="button"
                  >
                      {t("Add new request")}
                  </button>
              </div>
            <div className="flex flex-col items-center mt-6">
              <button
                  onClick={() => {
                    navigate('/dashboard')
                  }}
                  className="button"
              >
                <i className="icon-arrow-left button__icon button__icon--prefix"></i>
                {t("Back")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyGoalRequests;
