import {useState} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import arrowLeft from "../../Assets/gfx/icons/arrow-left.svg";
import timesCircle from "../../Assets/gfx/icons/times-circle.svg";
import ProfileImage from "../../Components/ProfileImage";
import { RiderModel } from "../../Api/Models/rider.model";
import { riderService } from "../../Api/Services/RiderService";
import { useLoading } from "../../Context/LoadingProvider";

interface RiderSearchProps {}

const RiderSearch: React.FunctionComponent<RiderSearchProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const navigate = useNavigate();

  const [riderId, setRiderId] = useState("");
  const [riders, setRiders] = useState<RiderModel[]>([]);

  const selectRider = (rider: RiderModel) => {
    navigate(`/rider/${rider.riderId}/start-check`);
  }

  const addToEndOfRiderId = (x: string | number) => {
    setRiderId(riderId + x.toString());
  };

  const removeLastCharacterFromRiderId = (x: string | number) => {
    setRiderId(riderId.substring(0, Math.max(riderId.length - 1, 0)));
  };

  const clearRiderId = (x: string | number) => {
    setRiderId("");
  };

  const fetchRiders = (e: React.FormEvent) => {
    e.preventDefault();
    startLoading("search-riders");
    riderService
      .search(riderId)
      .then((ridersResult) => {
        setRiders(ridersResult);
        if (ridersResult.length === 1) {
          selectRider(ridersResult[0]);
        }
      })
      .finally(() => {
        finnishLoading("search-riders");
      });
  };

  const getNumpadButtons = () => {
    const numpadButtons = [];
    for (let i = 1; i <= 9; i++) {
      numpadButtons.push({
        content: i,
        onClick: addToEndOfRiderId,
        extraClasses: "",
      });
    }
    numpadButtons.push({
      content: '<img src="' + arrowLeft + '" />',
      onClick: removeLastCharacterFromRiderId,
      extraClasses: "back",
    });

    numpadButtons.push({
      content: 0,
      onClick: addToEndOfRiderId,
      extraClasses: "",
    });

    numpadButtons.push({
      content: '<img src="' + timesCircle + '" />',
      onClick: clearRiderId,
      extraClasses: "clear",
    });
    return numpadButtons;
  };

  return (
    <div className="view-container background-2">
      <div className="view-content">
        <div className="heading text-center">{t("Rider Search")}</div>
        <form className="flex flex-col items-center" onSubmit={fetchRiders}>
          <p className="text-center text-white mb-2">{t("Rider ID")}</p>
          <input
            className="input-field text-blue see-through-background mb-2"
            type="number"
            step={1}
            min={0}
            value={riderId}
            onChange={(e) => {
              setRiderId(e.target.value);
            }}
          />
          <div className="numpad mb-4 w-full">
            {getNumpadButtons().map((buttonData) => (
              <div
                className="numpad__button__container"
                key={buttonData.content}
              >
                <div
                  className={"numpad__button " + buttonData.extraClasses}
                  onClick={() => buttonData.onClick(buttonData.content)}
                  dangerouslySetInnerHTML={{
                    __html: buttonData.content.toString(),
                  }}
                ></div>
              </div>
            ))}
          </div>

          <div className="flex justify-between mb-3">
            <button type="submit" className="button button--green">
              {t("Search")}
            </button>
          </div>
        </form>

        <div
          className="mb-4"
          style={{ position: "relative", minHeight: "100px", maxHeight: "200px", overflow: "scroll" }}
        >
          {riders.map((rider) => (
            <div onClick={() => selectRider(rider)} key={rider.riderId}>
              <ProfileImage imageUrl={rider.image} riderId={rider.riderId} />
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center flex-col">

          <Link to="/menu" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RiderSearch;
