import { UserModel } from "../Models/user.mode";
import request, { Method } from "../request";

interface userTokenResponse {
  user: UserModel;
  token: string;
  endpoint: string;
  isFirstLogin?: string;
}

class UserService {
  async login(email: string, password: string): Promise<userTokenResponse> {
    return request<userTokenResponse>(`/auth/login`, Method.POST, {
      data: {
        email: email,
        password: password,
        appId: 't_patroller',
      },
    });
  }

  async resetPassword(password: string): Promise<any> {
    return request<userTokenResponse>(`/auth/reset-password`, Method.POST, {
      data: {
        password: password,
      },
    });
  }

  async getCurrentUser() {
    return request<userTokenResponse>(`/auth/current-user`, Method.GET);
  }

  async logout() {
    return request(`/auth/logout`, Method.POST);
  }

  async getCookies() {
    return request(`/sanctum/csrf-cookie`, Method.GET, {}, false);
  }
}

export const userService = new UserService();
