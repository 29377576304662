import { useState } from "react";
import EnglishFlag from "../Assets/flags/en.svg";
import i18n, { languages } from "../i18n";

interface LanguageSelectorProps {}

const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = () => {
    const [showLanguageSelectModal, setShowLanguageSelectModal] = useState(false);

    const currentLanguage = languages.find((x) => x.key === i18n.language);

    return (
        <>
            <div
                className="flex justify-center mb-4 cursor-pointer"
                onClick={() => setShowLanguageSelectModal(true)}
            >
                <div className="language-selector">
                    <img
                        src={currentLanguage?.flag ?? EnglishFlag}
                        alt="Flag"
                        style={{ height: "20px" }}
                    />
                    <div className="flex items-center gap-2">
                        {currentLanguage?.name ?? "English"}
                        <i className="icon-arrow-down"></i>
                    </div>
                </div>
            </div>
            {showLanguageSelectModal && (
                <div
                    className="modal"
                    tabIndex={-1}
                    aria-modal="true"
                    role="dialog"
                >
                    <div className="modal__dialog">
                        <div className="modal__body">
                            <ul className="modal__list">
                                {languages.map((language) => (
                                    <li key={language.key}>
                                        <button
                                            className="flex items-center gap-4 w-full text-left p-2 hover:bg-gray-200"
                                            onClick={() => {
                                                i18n.changeLanguage(language.key);
                                                localStorage.setItem("language", language.key);
                                                setShowLanguageSelectModal(false);

                                                const htmlElement = document.getElementById("html");
                                                if (htmlElement) {
                                                    htmlElement.dir = language.rtl ? "rtl" : "ltr";
                                                    htmlElement.lang = language.key;
                                                }
                                            }}
                                        >
                                            <img
                                                src={language.flag}
                                                style={{ height: "34px" }}
                                                alt={`${language.name} Flag`}
                                            />
                                            {language.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LanguageSelector;
