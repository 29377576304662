import { createContext, useContext, useEffect, useState } from "react";
import { UserModel } from "../Api/Models/user.mode";
import { apiEndpointStorageKey } from "../Api/request";
import { userService } from "../Api/Services/UserService";
import { useLoading } from "./LoadingProvider";

interface UserContextInterface {
  user: UserModel | null;
  login: CallableFunction;
  logout: CallableFunction;
}

interface UserProviderPropsInterface {
  children: JSX.Element;
}

export const authTokenStorageKey = "apiToken";

const UserContext = createContext<UserContextInterface>({
  user: null,
  login: () => {},
  logout: () => {},
});

export const UserProvider = ({ children }: UserProviderPropsInterface) => {
  const [user, setUser] = useState<UserModel | null>(null);

  const { startLoading, finnishLoading } = useLoading();

  useEffect(() => {
    startLoading("get-cookies");
    userService
      .getCookies()
      .then(() => {
        if (localStorage.getItem(authTokenStorageKey)) {
          startLoading("get-current-user");

          userService
            .getCurrentUser()
            .then((user) => {
              setUser(user.user);
            })
            .finally(() => {
              finnishLoading("get-current-user");
            });
        }
      })
      .finally(() => {
        finnishLoading("get-cookies");
      });
    //eslint-disable-next-line
  }, []);

  const logout = async () => {
    return userService.logout().then(() => {
      localStorage.removeItem(authTokenStorageKey);
      setUser(null);
    });
  };

  const login = async (email: string, password: string) => {
    return userService.login(email, password).then((userToken) => {
      localStorage.setItem(authTokenStorageKey, userToken.token);
      localStorage.setItem(apiEndpointStorageKey, userToken.endpoint);
      localStorage.setItem('isFirstLogin', userToken.user.isFirstLogin !== undefined && userToken.user.isFirstLogin === '1' ? '1' : '0');
      setUser(userToken.user);
    });
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

export function useUser() {
  return useContext(UserContext);
}
