import { useTranslation } from "react-i18next";
import {
  ProfileType,
  RiderModel,
} from "../Api/Models/rider.model";
import Car from "../Assets/gfx/icons/vehicles/car.png";
import Motor from "../Assets/gfx/icons/vehicles/motorcycle.png";
import Police from "../Assets/gfx/icons/vehicles/police.png";
import FullTime from "../Assets/gfx/icons/8666609_user_icon.png";
import {useEffect, useState} from "react";
import {patrollerService} from "../Api/Services/PatrollerService";
import clockIcon from "../Assets/gfx/icons/clock.png";

interface ProfileDetailsProps {
  rider: RiderModel;
  gearExchange: boolean;
  hasGear?: boolean;
  gearMissing: number | null;
}

const ProfileDetails: React.FunctionComponent<ProfileDetailsProps> = ({
  rider,
  hasGear,
  gearExchange,
  gearMissing,
}) => {
  const { t } = useTranslation();

  const [latestCheck, setLatestCheck] = useState<string | null>(null);
  const [latestCheckName, setLatestCheckName] = useState<string | null>(null);
    const laravelDateTimeToDMY = (laravelDateTime: string) => {
        var parts = laravelDateTime.split(' ');
        var datePart = parts[0];

        var dateParts = datePart.split('-');
        var year = dateParts[0];
        var month = dateParts[1];
        var day = dateParts[2];

        return day + '-' + month + '-' + year;
    }
  const getVehicleImage = () => {
    if (rider.vehicle === 3) {
      return Car;
    }

    if (rider.vehicle === 2) {
      return Motor;
    }
  };

    useEffect(() => {
        patrollerService.latestCheckDate(rider.riderId).then(({date, name}) => {
            setLatestCheck(date ? laravelDateTimeToDMY(date) : null);
            setLatestCheckName(name ? name : null);
        });
        //eslint-disable-next-line
    }, []);

  return (
    <div className="gap-4px nav-card flex-col w-80 mb-4 background-transparent-white">
      <p className="text-small">
        {t("Rider ID")}: {rider.riderId}
        {rider.vehicle && (
            <img
                src={getVehicleImage()}
                width="30px"
                alt="Vehicle"
                style={{ display: "inline", marginLeft: "10px" }}
            />
        )}
      </p>
      <span className="font-bold font-14 text-center">
        {rider.name}

        {rider.profileType === ProfileType.TPATROLLER && (
          <img
            src={Police}
            width="25px"
            alt="Police"
            style={{ display: "inline", marginLeft: "5px" }}
          />
        )}
        {rider.profileType === ProfileType.FULL_TIME && (
          <img
            src={FullTime}
            width="25px"
            alt="Police"
            style={{ display: "inline", marginLeft: "5px" }}
          />
        )}
      </span>
      <span className="font-10">{rider.tpl_name}</span>
      {latestCheck ? (
        <>
          <p className="text-blue text-small text-center">
              <img src={clockIcon} className="mr-2" alt="" style={{display: "inline", width: "22px"}}/>
              {t('Latest check:')} {latestCheck} {t('by')} {latestCheckName}
          </p>
        </>
      ) : null}
    </div>
  );
};

export default ProfileDetails;
