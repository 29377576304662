import { useTranslation } from "react-i18next";
import {useNavigate, useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { RiderProfileLayoutContextInterface } from "../RiderProfileLayout";
import ImageInput from "../../../Components/ImageInput";
import {patrollerService} from "../../../Api/Services/PatrollerService";
import {useLoading} from "../../../Context/LoadingProvider";

const IdVerificationStep: React.FunctionComponent<any> = ({setStep, needsVehicleCheck, continueAfterFakeId}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
      rider,
    setSubTitle,
  } = useOutletContext<RiderProfileLayoutContextInterface>();
  const [isFakeId, setIsFakeId] = useState<boolean | undefined>(undefined);
  const [idImage, setIdImage] = useState<File[]>([]);
  const { startLoading, finnishLoading } = useLoading();
  const [displayFakeIdMessage, setDisplayFakeIdMessage] = useState<boolean>(false);

  useEffect(() => {
    setSubTitle();
  }, [t, setSubTitle]);

  const handleNext = () => {
      if (isFakeId === undefined) {
            return;
      }

      if (isFakeId && idImage.length === 0) {
            return;
      }

      startLoading('id-verification');
        console.log(isFakeId);
      patrollerService.saveIdStepCheckForRider(rider.riderId, !isFakeId, idImage[0] ?? undefined)?.then(() => {
          if (isFakeId) {
              console.log(continueAfterFakeId,needsVehicleCheck);
              setDisplayFakeIdMessage(true);
              if (continueAfterFakeId === false){
                  patrollerService.finishCheckForRider(rider.riderId);
                  setTimeout(() => {
                      navigate('/');
                  }, 1000);
                  return;
              }
          }

          if (needsVehicleCheck >= 0) {
              setStep(1);
              return;
          }

          setStep(2);
          return;
      }).finally(() => {
        finnishLoading('id-verification');
      });
  }

  setSubTitle(t('Does the rider have a fake ID?'));

  return (
          <>
            <div className="text-white text-center mb-4">
              {t("Does the rider have a fake ID?")}
            </div>
              {displayFakeIdMessage && <div className="text-red text-center mb-4 card">
                  {t("Fake ID reported for the rider")}
              </div>}
              <div className="text-red">{t("Please choose an option!")}</div>
              {rider.idCardNumber && <div className="license-plate">
                  {rider.idCardNumber}
              </div>}
            <div className="flex flex-col justify-start items-start w-80">
              <label className="checkbox mb-4">
                <input
                    type="checkbox"
                    onChange={(e) => setIsFakeId(e.target.checked)}
                    required
                    checked={isFakeId === true}
                />
                <div className="checkbox__success-icon no-border see-through-background"></div>
                <div className="checkbox__text text-white">{t("Yes")}</div>
              </label>

              <label className="checkbox mb-4">
                <input
                    type="checkbox"
                    onChange={(e) => setIsFakeId(!e.target.checked)}
                    required
                    checked={isFakeId === false}
                />
                <div className="checkbox__success-icon no-border see-through-background"></div>
                <div className="checkbox__text text-white">{t("No")}</div>
              </label>
            </div>


            <ImageInput
                images={idImage}
                setImages={setIdImage}
                title={t("Confirmation Picture")}
                multiple={false}
                required={false}
            />
              {isFakeId === true && (
                    <div className="text-white text-center mt-4">
                        {t("Please upload a picture of the fake ID")}
                    </div>
              )}


            <div className="flex flex-col items-center mt-6">
              <div>
                <button
                    type="submit"
                    className="button button--green"
                    onClick={() => handleNext()}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center mt-4">
              <div>
                <button type="submit" className="button" onClick={() => navigate('/')}>
                  {t("< Back")}
                </button>
              </div>
            </div>
          </>
  );
};

export default IdVerificationStep;
