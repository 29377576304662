import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingProvider";
import { useTranslation } from "react-i18next";
import {userService} from "../Api/Services/UserService";
import {LaravelValidationError} from "../Api/request";

const ResetPassword: React.FunctionComponent<object> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<Record<string, string[]> | null>(null);
  const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const resetPassword = () => {
      if (password !== passwordConfirmation || !passwordConfirmation || !password) {
            setValidationErrors({password: ["Passwords do not match"]});
            return;
      }

      startLoading("reset-password");
    userService.resetPassword(password).then(() => {
        navigate("/");
      }).catch((res: LaravelValidationError) => {
        setValidationErrors(res.errors);
      }).finally(() => {
          finnishLoading("reset-password");
    });
  };

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="">
            <div className="heading heading--small text-center font-bold">
                {t("Please update your password")}
            </div>
            <div className="card-list">
                {validationErrors?.password && (
                    <div className="input-error-text">
                        {validationErrors?.password?.map((x) => (
                            <>
                                <br />
                                <span key={x} style={{ color: "red" }}>
                      {x}
                    </span>
                            </>
                        ))}
                    </div>
                )}
            </div>
          <div>
              <div className="mb-6">
                  <label className="text-white">{t('New Password')}</label>
                  <input className="input-field text-blue see-through-background mt-4" type="password" placeholder={t('Password') || ''} onChange={(e) => setPassword(e.target.value)}/>
              </div>
              <div>
                  <label className="text-white">{t('New Password')}</label>
                <input className="input-field text-blue see-through-background mb-2 mt-4" type="password" placeholder={t('Password Confirmation') || ''} onChange={(e) => setPasswordConfirmation(e.target.value)}/>
            </div>
            <div className="flex flex-col items-center mt-6">
              <button
                  onClick={() => {
                    resetPassword()
                  }}
                  className="button button--blue"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
