import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import TabView from "../../Components/TabView";
import { TabType } from "../../Types/Tab.type";
import Gears from "../../Components/Profile/Gears";
import { RiderProfileLayoutContextInterface } from "./RiderProfileLayout";
import { RiderProductModel } from "../../Api/Models/rider.model";
import { riderService } from "../../Api/Services/RiderService";
import { useLoading } from "../../Context/LoadingProvider";
import { KitModel } from "../../Api/Models/product.model";
import CheckHistory from "./CheckHistory";

interface RiderProfileProps {}

const RiderProfile: React.FunctionComponent<RiderProfileProps> = () => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();
  const [allLoadingDone, setAllLoadingDone] = useState<boolean>(false);

  const {
    setPageTitle,
    rider,
    showTabsNavbar,
    setHasGear,
    setGearExchangeRequired,
    subTitle,
    setGearMissing,
  } = useOutletContext<RiderProfileLayoutContextInterface>();

  const [gears, setGears] = useState<RiderProductModel[]>([]);
  const [missingKitProducts, setMissingKitProducts] = useState<KitModel | null>(
    null
  );

  const fetchGears = () => {
    startLoading("get-profile-gear");
    riderService
      .listGear(rider.riderId)
      .then((gear) => {
        setGears(gear);

        if(gear.length === 0 || !gear){
          fetchGearsSecondTry();
        }
      })
      .finally(() => {
        finnishLoading("get-profile-gear");
      });

    fetchMissingKitProducts();
  };

  const fetchGearsSecondTry = () => {
    startLoading("get-profile-gear");
    riderService
        .listGear(rider.riderId)
        .then((gear) => {
          setGears(gear);
        })
        .finally(() => {
          finnishLoading("get-profile-gear");
        });
  };

  const fetchMissingKitProducts = () => {
    startLoading("get-missing-produts");
    riderService
      .getMissingKitProducts(rider.riderId)
      .then((kit) => {
        setMissingKitProducts(kit);
      })
      .finally(() => {
        finnishLoading("get-missing-produts");
        setAllLoadingDone(true);
      });
  };

  useEffect(() => {
    fetchGears();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHasGear(gears.length > 0);
    setGearExchangeRequired(
      gears.filter((x) => !x.exchangeAvailableAt).length > 0
    );
    //eslint-disable-next-line
  }, [gears]);

  useEffect(() => {
    setPageTitle();
  }, [t, setPageTitle]);

  useEffect(() => {
    setGearMissing(missingKitProducts?.products?.length ?? null);
  }, [missingKitProducts, setGearMissing]);

  const tabs: TabType[] = [
    {
      element: (
        <Gears
          gears={gears}
          rider={rider}
        />
      ),
      key: "gear",
      title: t("Gear"),
    },
    {
      element: (
          <CheckHistory />
      ),
      key: "history",
      title: t("History"),
    },
  ];

  if (allLoadingDone){
    return (
            <>
              <div className="flex flex-col justify-center items-center">
                <div className="w-80">
                  <p className="text-white text-center text-large">{t(subTitle)}</p>
                  <TabView tabs={tabs} showTabsNavbar={showTabsNavbar} />
                </div>
              </div>
            </>
    );
  }

  return null;

};

export default RiderProfile;
