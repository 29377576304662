import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Context/LoadingProvider";
import { useTranslation } from "react-i18next";
import {patrollerService} from "../Api/Services/PatrollerService";

const CreateDailyGoalRequest: React.FunctionComponent<object> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { t } = useTranslation();
    const [validationErrors, setValidationErrors] = useState<Record<
        string,
        string[]
    > | null>(null);
    const [date, setDate] = useState<string | null>(null);
    const [goal, setGoal] = useState<string | null>(null);
    const [note, setNote] = useState<string | null>(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!date || !goal || !note) {
        return;
    }
    startLoading("daily-goal-requests");
      patrollerService.createDailyGoalRequest(date, goal, note).then((res) => {
            navigate('/daily-goal-requests');
      }).catch((error) => {
            setValidationErrors(error.errors);
      }).finally(() => {
        finnishLoading("daily-goal-requests");
      });
  }

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="">
          <div className="card-list">
              <h2 className="heading heading--small text-center">
                    {t("Goal correction request")}
              </h2>
              <form onSubmit={handleSubmit} className="w-full px-4">
                  <div className="mb-4">
                      <label
                          className={
                              "bg-glass label-with-input" + (validationErrors?.date ? " error" : "")
                          }
                      >
                          <div className="label-with-input__text">{t("Date")}</div>
                          <input
                              type="date"
                              placeholder="Date"
                              required
                              onChange={(e) => setDate(e.target.value)}
                          />
                      </label>

                      {validationErrors?.date && (
                          <div className="input-error-text">
                              {validationErrors?.date?.map((x) => (
                                  <>
                                      <br />
                                      <span key={x} style={{ color: "red" }}>
                      {x}
                    </span>
                                  </>
                              ))}
                          </div>
                      )}
                  </div>

                  <div className="mb-4">
                      <label
                          className={
                              "bg-glass label-with-input" + (validationErrors?.daily_goal ? " error" : "")
                          }
                      >
                          <div className="label-with-input__text">{t("Goal")}</div>
                          <input
                              type="number"
                              placeholder="Goal"
                              min={0}
                              max={100}
                              required
                              onChange={(e) => setGoal(e.target.value)}
                          />
                      </label>

                      {validationErrors?.daily_goal && (
                          <div className="input-error-text">
                              {validationErrors?.daily_goal?.map((x) => (
                                  <>
                                      <br />
                                      <span key={x} style={{ color: "red" }}>
                      {x}
                    </span>
                                  </>
                              ))}
                          </div>
                      )}
                  </div>

                  <div className="mb-4" >
                      <label
                          className={
                              "bg-glass label-with-input" + (validationErrors?.note ? " error" : "") + " flex flex-col"
                          }
                          style={{paddingBottom: '12px'}}
                      >
                          <div className="label-with-input__text w-full">{t("Note")}</div>
                          <textarea
                                placeholder="Note"
                                required
                                onChange={(e) => setNote(e.target.value)}
                                className="w-full"
                          >
                          </textarea>
                      </label>

                      {validationErrors?.note && (
                          <div className="input-error-text">
                              {validationErrors?.note?.map((x) => (
                                  <>
                                      <br />
                                      <span key={x} style={{ color: "red" }}>
                      {x}
                    </span>
                                  </>
                              ))}
                          </div>
                      )}
                  </div>

                  <div className="text-center">
                      <button type="submit" className="button button--orange button--big">
                          {t("Submit")}
                      </button>
                  </div>
              </form>
            <div className="flex flex-col items-center mt-6">
              <button
                  onClick={() => {
                    navigate('/daily-goal-requests')
                  }}
                  className="button"
              >
                <i className="icon-arrow-left button__icon button__icon--prefix"></i>
                {t("Back")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDailyGoalRequest;
